import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-personal-profile',
  templateUrl: './personal-profile.component.html',
  styleUrls: ['./personal-profile.component.css']
})
export class PersonalProfileComponent implements OnInit {
  fullName: string;
  jobTitle: string;
  photoProfilePath: string;
  aboutMe: string;
  backgroundImage: string;

  constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.fullName = data.fullName;
    this.jobTitle = data.jobTitle;
    this.photoProfilePath = data.photoProfilePath;
    this.aboutMe = data.aboutMe;
    this.backgroundImage = data.backgroundImage;
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
