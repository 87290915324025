import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PersonalProfileComponent} from '../personal-profile/personal-profile.component';
import {AskMeComponent} from '../ask-me/ask-me.component';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {OwlOptions} from 'ngx-owl-carousel-o';

export interface CarouselElement {
  newsId: string;
  previewImage: string;
  title: string;
  description: string;
  link: string;
  textLink: string;
}

export interface DataAgentProfileElement {
  username: string;
  fullName: string;
  jobTitle: string;
  email: string;
  telephone: string;
  aboutMe: string;
  photoProfilePath: string;
  uploadedDateTime: Date;
  status: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  dataAgentProfile: DataAgentProfileElement;
  url = window.location.href;
  carouselElementsData: CarouselElement[] = [];
  currentBackground: string;
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 7000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['<span class="fas fa-chevron-left fa-2x"></span>', '<span class="fas fa-chevron-right fa-2x"></span>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      668: {
        items: 3
      },
      951: {
        items: 4
      },
      1367: {
        items: 5
      },
      1681: {
        items: 6
      },
      1921: {
        items: 7
      }
    },
    nav: true
  };

  constructor(private dialog: MatDialog, private route: ActivatedRoute, private http: HttpClient, private router: Router) {
    this.route.params.subscribe((params: Params) => {
      this.http.get(environment.cmsBackend + '/agent/' + params.username,
        {observe: 'response'}).subscribe(
        (response: HttpResponse<any>) => {
          if (response.body.data === null) {
            this.router.navigate(['']);
          } else {
            this.dataAgentProfile = response.body.data;
            sessionStorage.setItem('emailAgent', this.dataAgentProfile.email);
            if (this.dataAgentProfile.status === 'Active') {
              this.dataAgentProfile.photoProfilePath = 'data:image/jpeg;base64,' + this.dataAgentProfile.photoProfilePath;

              this.http.get(environment.cmsBackend + '/agent/corporate-news/show',
                {observe: 'response'}).subscribe(
                (responseNews: HttpResponse<any>) => {
                  this.carouselElementsData = responseNews.body.data;
                },
                (errorResponse) => {
                  console.log(errorResponse);
                });

              this.http.get(environment.cmsBackend + '/agent/background/show',
                {observe: 'response'}).subscribe(
                (responseBackground: HttpResponse<any>) => {
                  this.currentBackground = 'data:image/jpg;base64,' + responseBackground.body.data;
                  document.body.style.background = 'url(' + this.currentBackground + ') no-repeat center center fixed';
                  document.body.style.backgroundSize = 'cover';
                },
                (errorResponse) => {
                  console.log(errorResponse);
                });

            } else if (this.dataAgentProfile.status === 'Inactive') {
              this.router.navigate(['']);
            }
          }
        },
        (errorResponse) => {
          console.log(errorResponse);
        });
    });
  }

  openPersonalProfileDialog() {
    this.dialog.open(PersonalProfileComponent, {
        data: {
          backgroundImage: this.currentBackground,
          fullName: this.dataAgentProfile.fullName,
          jobTitle: this.dataAgentProfile.jobTitle,
          photoProfilePath: this.dataAgentProfile.photoProfilePath,
          aboutMe: this.dataAgentProfile.aboutMe
        },
        panelClass: 'personal-profile',
        width: '850px',
        disableClose: true,
      }
    );
  }

  openAskMeDialog() {
    this.dialog.open(AskMeComponent, {
        data: {
          email: this.dataAgentProfile.email,
          photoProfilePath: this.dataAgentProfile.photoProfilePath
        },
        panelClass: 'ask-me',
        width: '900px',
        height: '500px',
        disableClose: true,
      }
    );
  }

  shareToLinkedin() {
    window.open('https://www.linkedin.com/sharing/share-offsite/?url=' + this.url, 'popup', 'width=600,height=600,scrollbars=no,resizable=no');
  }

  shareToFacebook() {
    window.open('https://web.facebook.com/sharer/sharer.php?u=' + this.url, 'popup', 'width=600,height=600,scrollbars=no,resizable=no');
  }

  shareToTwitter() {
    window.open('https://twitter.com/intent/tweet?text=' + this.url, 'popup', 'width=600,height=600,scrollbars=no,resizable=no');
  }
}
