<mat-dialog-content>
  <div fxLayout="row">
    <div class="crop-profile">
      <img [src]="photoProfilePath" alt="Photo Agent">
    </div>
    <div class="crop-background">
      <img [src]="backgroundImage" alt="background">
      <div class="agent-bio">
        <div id="agent-name">{{fullName}}</div>
        <div id="agent-job">{{jobTitle}}</div>
      </div>
    </div>
    <button (click)="closeDialog()" mat-icon-button>
      <mat-icon id="btn-close-personal-profile">clear</mat-icon>
    </button>
  </div>

  <div class="content-wrapper-profile">
    <div id="personal-profile-title">Profil Pribadi</div>
    <div [innerHTML]="aboutMe" class="personal-profile-content">
    </div>
  </div>
</mat-dialog-content>
