<div>
  <section class="header">
    <div class="header-wrapper" fxLayout="row">
      <div class="header-left">
        <img alt="logo" class="logo" src="assets/img/chubb.png">
      </div>
      <span class="filler"></span>
      <div class="header-right">
        <ul>
          <li (click)="openAskMeDialog()">
            <i class="header-icon fal fa-comments fa-lg"></i>&nbsp;Tanya Saya
          </li>
        </ul>
      </div>
    </div>
  </section>

  <section class="profile">
    <div *ngIf="dataAgentProfile !== undefined" class="profile-wrapper" fxLayout="row">
      <div class="crop">
        <img [src]="dataAgentProfile.photoProfilePath" alt="Photo Agent">
      </div>

      <div class="agent-bio">
        <div id="agent-name">{{dataAgentProfile.fullName}}</div>
        <div id="agent-job">{{dataAgentProfile.jobTitle}}</div>
        <div><i class="fal fa-envelope-open-text fa-lg"></i>&nbsp;<a
          [href]="'mailto:' + dataAgentProfile.email">{{dataAgentProfile.email}}</a>
        </div>
        <div><i class="fal fa-mobile-alt fa-2x"></i>&nbsp;{{dataAgentProfile.telephone}}</div>
      </div>
    </div>

    <div class="profile-wrapper">
      <ul>
        <li (click)="openPersonalProfileDialog()" id="profil-pribadi">Profil Pribadi<i
          class="fal fa-angle-right fa-lg"></i></li>
      </ul>
    </div>
  </section>

  <section class="carousel">
    <div class="carousel-wrapper">
      <owl-carousel-o [options]="customOptions">
        <ng-template *ngFor="let item of carouselElementsData; let i = index" carouselSlide>
          <div [class]="'slide ' +'border' + i">
            <img [alt]="item.title" [src]="'data:image/jpeg;base64,'+ item.previewImage" [title]="item.title">
            <h2 [class]="'text' + i">{{item.title}}</h2>
            <p [class]="'text' + i">{{item.description}}</p>
            <p>
              <a [class]="'button-color'+i" [href]="item.link" target="_blank">{{item.textLink}}
                <span class="fas fa-chevron-right"></span>
              </a>
            </p>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </section>

  <div class="widget">
    <ul id="social-sidebar">
      <li>
        <a (click)="shareToLinkedin()" class="linkedin">
          <i class="fab fa-linkedin-in"></i><span>LinkedIn</span>
        </a>
      </li>
      <li>
        <a (click)="shareToFacebook()" class="facebook">
          <i class="fab fa-facebook-f"></i><span>Facebook</span>
        </a>
      </li>
      <li>
        <a (click)="shareToTwitter()" class="twitter">
          <i class="fab fa-twitter"></i><span>Twitter</span>
        </a>
      </li>
      <li>
        <a [href]="'https://api.whatsapp.com/send?text=' + url"
           class="whatsapp"
           target="_blank"><i class="fab fa-whatsapp"></i><span>Whatsapp</span>
        </a>
      </li>
      <li>
        <a [href]="'mailto:?subject=Home%20%7C%20Chubb%20Life&body=' + url"
           class="email">
          <i class="fal fa-envelope"></i><span>Email</span>
        </a>
      </li>
    </ul>
  </div>

</div>
