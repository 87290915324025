import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';

interface Enquiry {
  enquiry: string;
}

@Component({
  selector: 'app-ask-me',
  templateUrl: './ask-me.component.html',
  styleUrls: ['./ask-me.component.css']
})
export class AskMeComponent {

  form: FormGroup;
  formMessage = '';
  photoProfilePath: string;
  email: string;
  enquiries: Enquiry[] = [];
  toolTipDisabled = true;
  @ViewChild('tooltip') tooltip;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  constructor(private dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private http: HttpClient,
              private elementRef: ElementRef,
              private snackBar: MatSnackBar) {
    this.photoProfilePath = data.photoProfilePath;
    this.email = data.email;
    this.form = this.fb.group({
      enquiry: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required),
      mobileNumber: new FormControl('', Validators.required),
      emailAddress: new FormControl('', Validators.required),
      agreement: new FormControl(false, Validators.requiredTrue),
    });

    this.http.get(environment.cmsBackend + '/agent/enquiry/show', {
      observe: 'response',
      withCredentials: true
    }).subscribe(
      (response: HttpResponse<any>) => {
        this.enquiries = response.body.data;
      },
      (errorResponse) => {
        console.log(errorResponse);
      }
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.form.value.enquiry !== ''
      && this.form.value.message !== ''
      && this.form.value.mobileNumber !== ''
      && this.form.value.emailAddress !== '') {
      this.tooltip.hide();
      this.form.value.emailAgent = sessionStorage.getItem('emailAgent');
      this.http.post(environment.cmsBackend + '/agent/sendmail', this.form.value,
        {
          observe: 'response',
          withCredentials: true
        }).subscribe(
        (response: HttpResponse<any>) => {
          this.formGroupDirective.resetForm();
          this.formMessage = response.body.message;
          this.snackBar.open(response.body.message, 'TUTUP', {
            duration: 5000,
            panelClass: ['green-snackbar']
          });
        }, errorResponse => {
          console.log(errorResponse);
          this.formMessage = errorResponse.error.message;
          this.formGroupDirective.resetForm();
          this.snackBar.open(errorResponse.error.message, 'TUTUP', {
            duration: 5000,
            panelClass: ['red-snackbar']
          });
        }
      );
    } else {
      this.formMessage = 'All field must be filled';
    }
  }
}
