<section id="header">
  <div>
    <h1>Selamat Datang</h1>
    <p>Silahkan Tinggalkan Pesan Anda</p>
  </div>
</section>

<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <section id="body">
    <div fxFlex="65%" id="column1">
      Apa yang sedang kamu cari?
      <mat-form-field class="full-width">
        <mat-placeholder>
          Pilih pertanyaan
        </mat-placeholder>
        <mat-select formControlName="enquiry" required>
          <mat-option *ngFor="let enquiry of enquiries" [value]="enquiry.enquiry">
            {{enquiry.enquiry}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      Apapun yang bisa saya bantu untuk kamu?
      <mat-form-field class="full-width">
        <mat-label>Tinggalkan pesan</mat-label>
        <textarea autocomplete="off" formControlName="message" matInput
                  placeholder="Contoh: saya ingin bertanya tentang..."
                  required rows="4"></textarea>
      </mat-form-field>

      Nomor Handphone
      <mat-form-field class="full-width">
        <mat-label>Nomor Handphone Kamu</mat-label>
        <input autocomplete="off" formControlName="mobileNumber" matInput required type="text"/>
      </mat-form-field>

      Email
      <mat-form-field class="full-width">
        <mat-label>Alamat Email Kamu</mat-label>
        <input autocomplete="off" formControlName="emailAddress" matInput required type="text"/>
      </mat-form-field>

      <div class="full-width">
        <mat-checkbox #tooltip="matTooltip"
                      [matTooltipDisabled]="toolTipDisabled"
                      formControlName="agreement"
                      matTooltip="You must check this agreement"
                      matTooltipClass="tooltip-danger"
                      matTooltipPosition="above"
                      required></mat-checkbox>
        Semua informasi pribadi yang diperoleh di sini dikumpulkan untuk tujuan memungkinkan kami untuk menghubungi Anda
        dan digunakan sesuai dengan
        <a href="https://www.chubb.com/id-en/footer/internet-privacy-policy.aspx" target="_blank">
          Kebijakan Privasi Chubb Life</a>. Tanpa izin tertulis dari Anda, informasi pribadi yang
        dikumpulkan tidak akan ditransfer ke pihak ketiga di luar yang dinyatakan dalam Kebijakan Privasi Chubb Life.
        Anda dapat memilih untuk tidak memberikan informasi pribadi yang diperlukan, tetapi ini dapat menyebabkan kami
        tidak dapat menghubungi Anda. Saya menyetujui penggunaan data pribadi saya untuk tujuan di atas sesuai
        dengan Kebijakan Privasi Chubb Life.
      </div>
    </div>

    <div fxFlex="35%" id="column2">
      <div fxLayout="row">
        <div class="crop">
          <img [src]="photoProfilePath" alt="Photo Agent">
        </div>
        <div>
          Cara lain untuk menemukan saya
        </div>
      </div>
      <i class="far fa-envelope"></i>&nbsp;<a [href]="'mailto:'+email">{{email}}</a>
    </div>
  </section>

  <section id="footer">
    <mat-card-actions>
      <button (click)="closeDialog()" id="cancel" type="button">Batal</button>
      &nbsp;
      <button id="send" type="submit">Kirim</button>
    </mat-card-actions>
  </section>
</form>
