import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './core/app-routing.module';
import {HomeComponent} from './home/home.component';
import {AngularMaterialModule} from './core/material.module';
import {PersonalProfileComponent} from './personal-profile/personal-profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AskMeComponent} from './ask-me/ask-me.component';
import {ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule} from '@angular/common/http';
import {NotFoundComponent} from './not-found/not-found.component';
import {HttpXsrfInterceptor} from './service/http-xsrf-interceptor.service';
import {CarouselModule} from 'ngx-owl-carousel-o';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PersonalProfileComponent,
    AskMeComponent,
    NotFoundComponent
  ],
  imports: [
    AngularMaterialModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CarouselModule,
    HttpClientModule,
    ReactiveFormsModule,
    HttpClientXsrfModule.withOptions({cookieName: 'XSRF-TOKEN'})
  ],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule {
}
